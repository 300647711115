import React, { useState } from "react";
import Step1DefineAgent from "./components/Step1DefineAgent";
import Step2ProductInfo from "./components/Step2ProductInfo";
import Step3UserQuestions from "./components/FlowBuilder";
import Step4EndGoal from "./components/Step4EndGoal";
import Preview from "./components/Preview";
import Navigation from "./components/Navigation";

const App = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    agent_name: "",
    agent_duty: "",
    company_name: "",
    company_brief: "",
    product_info: "",
    product_name: "",
    user_questions: [],
    end: "",
  });

  const updateFormData = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1DefineAgent
            formData={formData}
            updateFormData={updateFormData}
          />
        );
      case 2:
        return (
          <Step2ProductInfo
            formData={formData}
            updateFormData={updateFormData}
          />
        );
      case 3:
        return (
          <Step3UserQuestions
            formData={formData}
            updateFormData={updateFormData}
          />
        );
      case 4:
        return (
          <Step4EndGoal
            formData={formData}
            updateFormData={updateFormData}
          />
        );
      default:
        return <Preview formData={formData} />;
    }
  };

  const submitToBackend = () => {
    console.log("Submitting:", formData);
    // Integrate backend API
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-4 text-center">
          Sales AI Prompt Builder
        </h1>
        {renderStep()}
        <Navigation
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          maxSteps={5}
        />
        {currentStep === 5 && (
          <div className="mt-6">
            <Preview formData={formData} />
            <button
              onClick={submitToBackend}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-700"
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
