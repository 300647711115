import React from "react";

const Navigation = ({ currentStep, setCurrentStep, maxSteps }) => {
  return (
    <div className="flex justify-between mt-4">
      <button
        onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 1))}
        className={`px-4 py-2 rounded-lg shadow ${
          currentStep === 1
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-blue-600 text-white hover:bg-blue-700"
        }`}
        disabled={currentStep === 1}
      >
        Back
      </button>
      <button
        onClick={() => setCurrentStep((prev) => Math.min(prev + 1, maxSteps))}
        className={`px-4 py-2 rounded-lg shadow ${
          currentStep === maxSteps
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-blue-600 text-white hover:bg-blue-700"
        }`}
        disabled={currentStep === maxSteps}
      >
        Next
      </button>
    </div>
  );
};

export default Navigation;
