import React from "react";

const Step2ProductInfo = ({ formData, updateFormData }) => {
  return (
    <div>
      <h2 className="text-xl font-bold text-gray-700 mb-4">
        Step 2: Provide Product Information
      </h2>
      <label className="block mb-2 text-gray-600">
        Product Name:
        <input
          type="text"
          value={formData.product_name}
          onChange={(e) => updateFormData("product_name", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg mt-1"
        />
      </label>
      <label className="block mb-2 text-gray-600">
        Product Info:
        <textarea
          value={formData.product_info}
          onChange={(e) => updateFormData("product_info", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg mt-1"
        />
      </label>
    </div>
  );
};

export default Step2ProductInfo;
