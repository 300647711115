import React, { useCallback } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import QuestionNode from './QuestionNode';
import './Step3.css'; // Import your main CSS file
import {MessageNode} from './CustomNodes';
const nodeTypes = {
  question: QuestionNode,
  message: MessageNode
  // Add other node types if needed
};

const initialNodes = [
  
];

const Step3DynamicFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
   console.log("nodes", nodes)
  // Handle node data changes
  const onNodeDataChange = useCallback((id, data) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, ...data } } : node
      )
    );
  }, []);

  // Handle new connections
  const onConnect = useCallback(
    (params) => {
      const sourceHandleId = params.sourceHandle; // e.g., 'answer-0'
      const answerIndex = parseInt(sourceHandleId.split('-')[1], 10);
      const sourceNode = nodes.find((node) => node.id === params.source);
      const answerText = sourceNode.data.answers[answerIndex];

      // Update conditions in the source node
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === params.source) {
            const updatedConditions = {
              ...node.data.conditions,
              [answerIndex]: params.target,
            };
            return {
              ...node,
              data: { ...node.data, conditions: updatedConditions },
            };
          }
          return node;
        })
      );

      // Add label to edge
      const newEdge = {
        ...params,
        label: answerText,
        animated: true,
        style: { stroke: '#555' },
        labelBgStyle: { fill: '#fff', color: '#555', fillOpacity: 0.7 },
      };

      setEdges((eds) => addEdge(newEdge, eds));
    },
    [setEdges, setNodes, nodes]
  );

  // Function to add a new question node
  const addQuestionNode = () => {
    const id = `${nodes.length + 1}`;
    const newNode = {
      id,
      type: 'question',
      data: {
        question: '',
        answers: [],
        conditions: {},
        onChange: onNodeDataChange,
        id,
      },
      position: { x: 250, y: 100 * nodes.length },
    };
    setNodes((nds) => nds.concat(newNode));
  };

  return (
    <div className="flow-builder">
      <div className="header">
        <h1>Conversation Flow Builder</h1>
        <button onClick={addQuestionNode} className="add-node-button">
          Add Question
        </button>
      </div>
      <div className="flow-container">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
        >
          <MiniMap />
          <Controls />
          <Background />
        </ReactFlow>
      </div>
    </div>
  );
};

export default Step3DynamicFlow;
