import React from "react";

const Step1DefineAgent = ({ formData, updateFormData }) => {
  return (
    <div>
      <h2 className="text-xl font-bold text-gray-700 mb-4">
        Step 1: Define the Agent
      </h2>
      <label className="block mb-2 text-gray-600">
        Agent Name:
        <input
          type="text"
          value={formData.agent_name}
          onChange={(e) => updateFormData("agent_name", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg mt-1"
        />
      </label>
      <label className="block mb-2 text-gray-600">
        Agent Duty:
        <select
          value={formData.agent_duty}
          onChange={(e) => updateFormData("agent_duty", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg mt-1"
        >
          <option value="">Select Duty</option>
          <option value="sales_assistant">Sales Assistant</option>
          <option value="loan_advisor">Loan Advisor</option>
        </select>
      </label>
      <label className="block mb-2 text-gray-600">
        Company Name:
        <input
          type="text"
          value={formData.company_name}
          onChange={(e) => updateFormData("company_name", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg mt-1"
        />
      </label>
      <label className="block mb-2 text-gray-600">
        Company Brief:
        <textarea
          value={formData.company_brief}
          onChange={(e) => updateFormData("company_brief", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg mt-1"
        />
      </label>
    </div>
  );
};

export default Step1DefineAgent;
