import React from "react";

const Step4EndGoal = ({ formData, updateFormData }) => {
  return (
    <div>
      <h2>Step 4: Define the End Goal</h2>
      <label>
        End Goal:
        <textarea
          value={formData.end}
          onChange={(e) => updateFormData("end", e.target.value)}
        />
      </label>
    </div>
  );
};

export default Step4EndGoal;
