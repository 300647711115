import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';

const MessageNode = ({ data }) => {
  return (
    <div className="node message-node">
      <div>{data.content}</div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

const QuestionNode = ({ data }) => {
  const [content, setContent] = useState(data.content);

  const handleChange = (e) => {
    setContent(e.target.value);
    data.onChange(data.id, e.target.value);
  };

  return (
    <div className="node question-node">
      <input type="text" value={content} onChange={handleChange} />
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Top} />
    </div>
  );
};

const ChoiceNode = ({ data }) => {
  const [options, setOptions] = useState(data.options || []);

  const addOption = () => {
    setOptions([...options, '']);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
    data.onChange(data.id, newOptions);
  };

  return (
    <div className="node choice-node">
      <div>{data.content}</div>
      {options.map((option, index) => (
        <input
          key={index}
          type="text"
          value={option}
          onChange={(e) => handleOptionChange(index, e.target.value)}
        />
      ))}
      <button onClick={addOption}>Add Option</button>
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Top} />
    </div>
  );
};

// Add other node types as needed

export { MessageNode, QuestionNode, ChoiceNode };
