import React from "react";

const Preview = ({ formData }) => {
  return (
    <div>
      <h2>Preview</h2>
      <pre>{JSON.stringify(formData, null, 2)}</pre>
    </div>
  );
};

export default Preview;
