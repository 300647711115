// QuestionNode.jsx
import React, { useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import './QuestionNode.css';

const QuestionNode = ({ data, id }) => {
  const [question, setQuestion] = useState(data.question || '');
  const [answers, setAnswers] = useState(data.answers || []);
  const [answerIsRequired, setAnswerIsRequired] = useState(data.answerIsRequired || false);

  useEffect(() => {
    data.onChange(id, { question, answers, answerIsRequired });
  }, [question, answers, answerIsRequired]);

  // Update question text
  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  // Add a new answer option
  const addAnswer = () => {
    setAnswers([...answers, '']);
  };

  // Update an existing answer
  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  // Remove an answer option
  const removeAnswer = (index) => {
    const newAnswers = answers.filter((_, idx) => idx !== index);
    setAnswers(newAnswers);
  };

  // Toggle answer requirement
  const toggleAnswerIsRequired = () => {
    setAnswerIsRequired(!answerIsRequired);
  };

  return (
    <div className="question-node">
      <div className="node-header">Question Node</div>
      <textarea
        value={question}
        onChange={handleQuestionChange}
        placeholder="Enter your question here..."
        className="question-input"
      />
      <div className="answers-section">
        <div className="answers-header">
          <span>Possible Answers</span>
          <button onClick={addAnswer} className="add-answer-button">
            Add Answer
          </button>
        </div>
        {answers.map((answer, index) => (
          <div key={index} className="answer-item">
            <Handle
              type="source"
              position={Position.Right}
              id={`answer-${index}`}
              className="answer-handle"
            />
            <input
              type="text"
              value={answer}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              placeholder={`Answer ${index + 1}`}
              className="answer-input"
            />
            <button onClick={() => removeAnswer(index)} className="remove-answer-button">
              Remove
            </button>
          </div>
        ))}
      </div>
      <div className="answer-required-toggle">
        <label>
          <input
            type="checkbox"
            checked={answerIsRequired}
            onChange={toggleAnswerIsRequired}
          />
          Answer is required
        </label>
      </div>
      {/* Handle for incoming connections */}
      <Handle type="target" position={Position.Top} />
    </div>
  );
};

export default QuestionNode;
